<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./form-basic-data-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonly" :disabled="disabled">
        <!-- Rolle des Meldenden auswählen -->
        <v-container fluid py-0 my-0 mt-4>
            <v-row>
                <v-col>
                    <base-select
                        v-model.lazy="role"
                        :items="roleItems"
                        :error-messages="roleErrors"
                        :label="$t('role-label')"
                        :placeholder="$t('role-placeholder')"
                        :required="isRequiredField($v.role)"
                        dense
                        :item-text="(item) => $t(`task-role-${item.value}`)"
                    >
                        <template v-slot:prepend-item>
                            <div
                                class="text-body-1 pl-3"
                                v-html="$t('role-label')"
                            ></div>
                            <v-divider></v-divider>
                        </template>
                    </base-select>
                </v-col>
            </v-row>
            <!-- Objekttyp auswählen -->
            <v-row>
                <v-col class="pt-0">
                    <base-select
                        :disabled="objectType !== null"
                        v-model.lazy="objectType"
                        :items="objectTypeItems"
                        :error-messages="objectTypeErrors"
                        :label="$t('object-type-label')"
                        :placeholder="$t('object-type-placeholder')"
                        :required="isRequiredField($v.objectType)"
                        dense
                        :item-text="
                            (item) => $t(`task-object-type-${item.value}`)
                        "
                    >
                        <template v-slot:prepend-item>
                            <div
                                class="text-body-1 pl-3"
                                v-html="$t('object-type-label')"
                            ></div>
                            <v-divider></v-divider>
                        </template>
                    </base-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <base-text-field
                        v-model.lazy="workingTitle"
                        :error-messages="workingTitleErrors"
                        :counter="$v.workingTitle.$params.maxLength.max"
                        :label="$t('working-title-label')"
                        :placeholder="$t('working-title-placeholder')"
                        :required="isRequiredField($v.workingTitle)"
                    ></base-text-field>
                </v-col>
            </v-row>
            <!-- Objektdetails erfassen -->
            <v-row>
                <v-col>
                    <WManufacturerSearch
                        class="pb-2"
                        ref="manufacturerSearch"
                        :value="manufacturer"
                        @change="manufacturer = $event"
                        :label="$t('manufacturer-label')"
                        :placeholder="$t('manufacturer-placeholder')"
                        :error-messages="manufacturerErrors"
                        :required="isRequiredField($v.manufacturer)"
                        :disabled="disabled"
                        :readonly="readonlyModus"
                    >
                    </WManufacturerSearch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <base-text-field
                        v-model.lazy="factoryNumber"
                        :error-messages="factoryNumberErrors"
                        :counter="$v.factoryNumber.$params.maxLength.max"
                        :label="$t('factory-number-label')"
                        :placeholder="$t('factory-number-placeholder')"
                        :required="isRequiredField($v.factoryNumber)"
                    ></base-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <base-select
                        v-model.lazy="constructionYear"
                        :items="constructionYearItems"
                        :error-messages="constructionYearErrors"
                        :label="$t('construction-year-label')"
                        :placeholder="$t('construction-year-placeholder')"
                        :required="isRequiredField($v.constructionYear)"
                        dense
                    >
                        <template v-slot:item="{ item }">
                            <span
                                v-if="constructionYearItems.indexOf(item) === 0"
                                >{{ item }} ({{ $t("this-year") }})</span
                            >
                            <span
                                v-else-if="
                                    constructionYearItems.indexOf(item) === 1
                                "
                                >{{ item }} ({{ $t("last-year") }})</span
                            >
                            <span
                                v-else-if="
                                    constructionYearItems.indexOf(item) === 2
                                "
                                >{{ item }} ({{ $t("two-years-ago") }})</span
                            >
                            <span v-else>{{ item }}</span>
                        </template>
                    </base-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <base-select
                        v-if="
                            constructionYear &&
                            constructionYear < new Date().getFullYear()
                        "
                        v-model.lazy="inOperationYear"
                        :items="inOperationYearItems"
                        :error-messages="inOperationYearErrors"
                        :label="$t('in-operation-year-label')"
                        :required="isRequiredField($v.inOperationYear)"
                        dense
                        clearable
                    >
                    </base-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <base-textarea
                        v-model.lazy="intendedUse"
                        :label="$t('intended-use-label')"
                        :error-messages="intendedUseErrors"
                        :counter="$v.intendedUse.$params.maxLength.max"
                        no-resize
                        :required="isRequiredField($v.intendedUse)"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import {
    required,
    maxLength,
    minLength,
    requiredIf
} from "vuelidate/lib/validators";
import { ROLE_TYPES, PROCESS_TYPES_LIST, OBJECT_TYPES } from "@/data/globals";
import WManufacturerSearch from "@/components/fields/WManufacturerSearch.vue";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormBasicData",
    components: {
        WManufacturerSearch
    },
    mixins: [validateMixin],
    validations: {
        role: { required },
        objectType: { required },
        manufacturer: {
            required
        },
        workingTitle: {
            maxLength: maxLength(50),
            required
        },
        factoryNumber: {
            maxLength: maxLength(30),
            required
        },
        constructionYear: { required },
        intendedUse: {
            required,
            maxLength: maxLength(200),
            minLength: minLength(2)
        },
        inOperationYear: {
            required: requiredIf(function () {
                if (this.constructionYear < new Date().getFullYear()) {
                    return true;
                }
                return false;
            })
        }
    },
    props: ["value", "disabled", "readonly"],
    data() {
        return {
            roleItems: [
                {
                    value: ROLE_TYPES.OPERATOR
                },
                {
                    value: ROLE_TYPES.OPERATOR_BEHALF
                }
            ],
            process: PROCESS_TYPES_LIST[0],
            objectTypeItems: [
                {
                    value: OBJECT_TYPES.PRESSURE_EQUIPMENT
                },
                {
                    value: OBJECT_TYPES.FIRE_BOTTLES_EQUIPMENT
                },
                {
                    value: OBJECT_TYPES.PIPES_STEAM_HOT_WATER
                }
            ]
        };
    },
    methods: {
        reset() {
            this.$v.$reset();
            this.role = null;
            this.objectType = null;
            this.workingTitle = null;
            this.manufacturer = null;
            this.factoryNumber = null;
            this.constructionYear = null;
            this.intendedUse = null;
            this.inOperationYear = null;
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.$v.$touch();
            } else {
                return;
            }
        },
        setFakeData() {
            this.step = 3;
            this.role = ROLE_TYPES.OPERATOR;
            this.process = PROCESS_TYPES_LIST[0];
            this.objectType = OBJECT_TYPES.PRESSURE_EQUIPMENT;
            this.workingTitle = "Meine Arbeitstitel";
            this.factoryNumber = "238832424-34234";
            this.constructionYear = 2017;
            this.intendedUse =
                "Curabitur aliquet quam id dui posuere blandit. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.";
            this.inOperationYear = 2018;
        },
        disableForm() {
            if (this.showLock && this.readonlyLock) {
                return true;
            } else {
                return this.disabled;
            }
        }
    },
    computed: {
        role: {
            get() {
                return this.value.role;
            },
            set(value) {
                this.value.role = value;
            }
        },
        objectType: {
            get() {
                return this.value.objectType;
            },
            set(value) {
                this.value.objectType = value;
            }
        },
        workingTitle: {
            get() {
                return this.value.workingTitle;
            },
            set(value) {
                this.value.workingTitle = value;
            }
        },
        manufacturer: {
            get() {
                return this.value.manufacturer;
            },
            set(value) {
                this.value.manufacturer = value;
            }
        },
        factoryNumber: {
            get() {
                return this.value.factoryNumber;
            },
            set(value) {
                this.value.factoryNumber = value;
            }
        },
        constructionYear: {
            get() {
                return this.value.constructionYear;
            },
            set(value) {
                this.value.constructionYear = value;
            }
        },
        intendedUse: {
            get() {
                return this.value.intendedUse;
            },
            set(value) {
                this.value.intendedUse = value;
            }
        },
        inOperationYear: {
            get() {
                return this.value.inOperationYear;
            },
            set(value) {
                this.value.inOperationYear = value;
            }
        },
        constructionYearItems() {
            let currentYear = new Date().getFullYear();
            let years = [];
            let startYear = currentYear - 150;
            for (var i = startYear; i <= currentYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        inOperationYearItems() {
            let endYear = new Date().getFullYear() + 2;
            let minYear = this.constructionYear;
            let years = [];
            let startYear = minYear;
            for (var i = startYear; i <= endYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        roleErrors() {
            const errors = [];
            if (!this.$v.role.$dirty) {
                return errors;
            }
            if (!this.$v.role.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        objectTypeErrors() {
            const errors = [];
            if (!this.$v.objectType.$dirty) {
                return errors;
            }
            if (!this.$v.objectType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        workingTitleErrors() {
            const errors = [];
            if (!this.$v.workingTitle.$dirty) {
                return errors;
            }
            if (!this.$v.workingTitle.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.workingTitle.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.workingTitle.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        manufacturerErrors() {
            const errors = [];
            if (!this.$v.manufacturer.$dirty) {
                return errors;
            }
            if (!this.$v.manufacturer.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        factoryNumberErrors() {
            const errors = [];
            if (!this.$v.factoryNumber.$dirty) {
                return errors;
            }
            if (!this.$v.factoryNumber.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.factoryNumber.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.factoryNumber.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        constructionYearErrors() {
            const errors = [];
            if (!this.$v.constructionYear.$dirty) {
                return errors;
            }
            if (!this.$v.constructionYear.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        intendedUseErrors() {
            const errors = [];
            if (!this.$v.intendedUse.$dirty) {
                return errors;
            }
            if (!this.$v.intendedUse.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.intendedUse.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.intendedUse.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.intendedUse.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        minLength: this.$v.intendedUse.$params.minLength.max
                    })
                );
            }
            return errors;
        },
        inOperationYearErrors() {
            const errors = [];
            if (!this.$v.inOperationYear.$dirty) {
                return errors;
            }
            if (!this.$v.inOperationYear.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    watch: {
        constructionYearMenu(val) {
            val &&
                this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
        }
    }
};
</script>
