<template>
    <v-app id="inspire" class="app">
        <div class="w-bodycontainer ma-0 pa-0 backgroundOutter">
            <div class="w-bodycontainer-inner backgroundInner d-flex flex-column">
                <AppNavigationDrawer
                    v-if="$vuetify.breakpoint.width < 1024"
                    v-on:navigation-item-click="onNavigationItemClick"
                    :value="drawerRight.show"
                    @input="drawerRight.show = $event"
                >
                    <template v-slot:navigation-content>
                        <NavigationContent
                            v-on:navigation-item-click="onMenuIconClick"
                        />
                    </template>
                </AppNavigationDrawer>
                <AppToolbar
                    ref="appToolbar"
                    @menu-icon-click="onMenuIconClick"
                    :drawer="drawerRight.show"
                    :showMenuBtn="$vuetify.breakpoint.width <= 1024"
                    class="flex-grow-0"
                >
                    <template v-slot:app-menu>
                        <app-menu
                            v-if="$vuetify.breakpoint.width > 1024"
                            v-model="menu"
                        >
                            <template v-slot:content>
                                <NavigationContent
                                    @navigation-item-click="menu = false"
                                />
                            </template>
                        </app-menu>
                    </template>
                </AppToolbar>
                <v-main class="ma-0 pa-0 align-stretch">
                    <v-container fluid>
                        <router-view></router-view>
                    </v-container>
                    <!-- Go to top -->
                    <btn-scroll-top maxContentSize="1024" />
                </v-main>
                <AppFooter/>
            </div>
        </div>
        <CookieBanner/>
    </v-app>
</template>
<script>
import AppFooter from "../components/app-footer.vue";
import AppToolbar from "../components/app-toolbar.vue";
import AppNavigationDrawer from "../components/app-navigation-drawer.vue";
import BtnScrollTop from "../components/btn-scroll-top.vue";
import CookieBanner from "../components/cookie-banner.vue";
import { mapGetters } from "vuex";
import AppMenu from "../components/app-menu.vue";
import NavigationContent from "../components/navigation-content.vue";
export default {
    components: {
        AppFooter,
        AppToolbar,
        AppNavigationDrawer,
        BtnScrollTop,
        CookieBanner,
        AppMenu,
        NavigationContent
    },
    data() {
        return {
            drawerRight: {
                show: false
            },
            menu: false
        };
    },
    computed: {
        ...mapGetters("auth", {
            isAuth: "isAuthenticated",
            userFullname: "userFullname",
            accountDocId: "accountDocId"
        })
    },
    methods: {
        onMenuIconClick() {
            this.drawerRight.show = !this.drawerRight.show;
        },
        onNavigationItemClick() {
            this.menu = false;
            this.drawerRight.show = false;
        },
        fetchOpenRegistrations() {
            let data = {};
            data.token = this.$store.state.auth.token;
            data.accountDocId = this.$store.state.auth.accountDocId;
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch("registrations/fetchOpen", data)
                    .then((response) => {
                        let status = response.status;
                        if (status === 200) {
                            this.$store.commit("registrations/clearAll");
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        this.$toast.error("Fehler: " + error);
                        this.$store.commit("registrations/clearAll");
                        reject(error);
                    });
            });
        },
        fetchData() {
            this.loader().show();
            this.fetchOpenRegistrations()
                .then((response) => {
                    if (response.status === 200) {
                        this.loader().hide();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        }
    },
    watch: {
        isAuth: {
            handler: function (auth) {
                if (auth) {
                    this.fetchData();
                }
            },
            immediate: true
        }
    }
};
</script>
<style src="./base.styl" lang="stylus"></style>
