<i18n       src="./display-address-inline-i18n.yaml"></i18n>
<template>
    <v-container v-if="address" fluid fill-height class="pa-0 ma-0">
        <v-row
            no-gutters
            align="center"
            v-if="(showSameAsOperator && address.sameAddressAsOperator)
                  || (showSameAsBilling && address.sameAddressAsBilling)"
        >
            <span v-if="showSameAsOperator && address.sameAddressAsOperator">
                {{ $t("same-address-as-operator") }}
            </span>
            <span v-if="showSameAsBilling && address.sameAddressAsBilling">
                {{ $t("same-address-as-billing") }}
            </span>
        </v-row>
        <span v-else>
            <div v-if="address.uidFormatted">
                {{ address.uidFormatted }}
                <CopyToClipboard
                    v-if="showCopyBtn"
                    :value="address.uidFormatted"
                />
            </div>
            <v-row no-gutters align="center">
                <span>
                    <span v-if="address.addressType === ADDRESS_TYPES.COMPANY">
                        <span v-if="address.companyName && !address.department">
                            {{ address.companyName }},
                        </span>
                        <span
                            v-else-if="
                                !address.companyName && address.department
                            "
                        >
                            {{ address.department }},
                        </span>
                        <span v-else>
                            {{ address.companyName }} ({{
                                address.department
                            }}),
                        </span>
                        <span v-if="address.salutation && address.name">
                            {{ salutation }} {{ address.name }},
                        </span>
                    </span>
                    <span v-else>
                        <span v-if="address.firstName && address.familyName">
                            {{ address.firstName }} {{ address.familyName }},
                        </span>
                    </span>
                    <span v-if="address.additionalAddressLines">
                        <span
                            v-if="address.addressLine1 && !address.addressLine2"
                        >
                            {{ $t("additional-address-lines-prefix") }}
                            {{ address.addressLine1 }},
                        </span>
                        <span v-if="address.addressLine2">
                            {{ $t("additional-address-lines-prefix") }}
                            {{ address.addressLine1 }}
                            {{ address.addressLine2 }},
                        </span>
                    </span>
                </span>
                <span class="pl-1">
                    <span v-if="address.street">
                        {{ address.street
                        }}<span class="pl-1" v-if="address.houseNr">{{
                            address.houseNr
                        }}</span
                        >,
                    </span>
                    <span class="pr-2">
                        <span v-if="address.country"
                            >({{ address.country }})</span
                        >
                        <span
                            v-if="
                                address.country &&
                                address.zipCode &&
                                address.city
                            "
                            >-</span
                        >
                        <span v-if="address.zipCode && address.city"
                            >{{ `${address.zipCode} ${address.city}` }}
                        </span>
                        <span v-if="address.country === 'CH' && address.canton"
                            >({{ address.canton }})</span
                        >
                    </span>
                </span>
            </v-row>
            <v-row v-if="address.communication" no-gutters align="center">
                <span
                    v-if="address.communication.phone"
                    :class="
                        address.communication.fax || address.communication.email
                            ? 'pr-2'
                            : null
                    "
                >
                    <v-icon
                        class="mr-1"
                        x-small
                        :href="'tel:' + address.communication.phone"
                        >mdi-phone</v-icon
                    >
                    <a
                        class="text-decoration-none"
                        v-bind:href="`tel:${address.communication.phone}`"
                        >{{ address.communication.phone }}</a
                    >
                    <span
                        v-if="
                            address.communication.fax ||
                            address.communication.email
                        "
                        >,</span
                    >
                </span>
                <span
                    v-if="address.communication.fax"
                    :class="address.communication.email ? 'pr-2' : null"
                >
                    <v-icon class="mr-1" x-small>mdi-fax</v-icon>
                    <span>{{ address.communication.fax }}</span>
                    <span v-if="address.communication.email">,</span>
                </span>
                <span v-if="address.communication.email">
                    <v-icon class="mr-1" x-small>mdi-email</v-icon>
                    <a
                        class="text-decoration-none"
                        v-bind:href="`mailto:${address.communication.email}`"
                        >{{ address.communication.email }}</a
                    >
                </span>
            </v-row>
        </span>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { ADDRESS_TYPES } from "@/data/globals.js";
export default {
    name: "DisplayAddressInline",
    components: {
        CopyToClipboard: () =>
            import("@/components/general/utlis/copy-to-clipboard.vue")
    },
    props: {
        address: {
            type: Object,
            default: () => {}
        },
        showSameAsOperator: {
            type: Boolean,
            default: false
        },
        showSameAsBilling: {
            type: Boolean,
            default: false
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        }
    },
    data: () => ({
        dialogExternalLink: false,
        ADDRESS_TYPES: ADDRESS_TYPES
    }),
    computed: {
        ...mapGetters({
            salutations: "attributes/salutations"
        }),
        salutation() {
            if (
                !this.salutations ||
                this.salutations.length === 0 ||
                !this.address.salutation
            )
                return null;
            const locale = this.$i18n.locale;
            const salutaionItem = this.salutations.find(
                (s) => s.key === this.address.salutation
            );
            if (locale && salutaionItem) {
                return salutaionItem.value.title[locale];
            }
            return null;
        }
    }
};
</script>
