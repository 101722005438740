<i18n src="./app-footer-i18n.yaml"></i18n>
<template>
  <v-footer>
    <v-row justify="end" no-gutters>
      <v-btn
        :href="getLink"
        text
        target="_blank"
      >
        {{ $t("privacy-policy") }}
      </v-btn>
    </v-row>
  </v-footer>
</template>
<script>
  import { mapGetters } from "vuex"
  import { PRIVCY_POLICY_LINKS } from "@/data/globals"

  export default {
    computed: {
      ...mapGetters("settings", {
          registrationOffice: "getRegistrationOffice"
      }),
      getLink() {
        let registrationOffice = this.registrationOffice
        let languageCode = this.$root.$i18n.locale

        if (['SUVA', 'SVTI'].indexOf(registrationOffice) < 0) {
          registrationOffice = 'SVTI'
        }

        if (['de', 'fr', 'it'].indexOf(languageCode) < 0) {
          languageCode = 'de'
        }

        return PRIVCY_POLICY_LINKS[registrationOffice][languageCode]
      }
    }
  }
</script>
