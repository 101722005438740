<i18n src="./cookie-banner-i18n.yaml"></i18n>
<template>
  <v-dialog
    v-model="dialog"
    :persistent="true"
  >
    <v-card
      class="cookie-dialog"
      rounded="0"
    >
      <v-card-title>
        Cookies
      </v-card-title>
      <v-card-text
        class="d-flex align-end"
      >
        <div class="flex-grow-1 d-flex flex-column flex-nowrap">
          <div>
            {{ $t("cookie-text") }}
          </div>
          <div class="mt-2">
            <a
              :href="getLink"
              target="_blank"
            >
              {{ $t("privacy-policy") }}
            </a>
          </div>
        </div>
        <base-btn
          class="ml-8"
          type="primary"
          @click="accept"
        >
          {{ $t("cookie-accept") }}
        </base-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters } from "vuex"
  import { PRIVCY_POLICY_LINKS } from "@/data/globals"

  const COOKIES_ACCEPTED_KEY = 'cookiesAccepted'

  export default {
    computed: {
      ...mapGetters("settings", {
          registrationOffice: "getRegistrationOffice"
      }),
      getLink() {
        let registrationOffice = this.registrationOffice
        let languageCode = this.$root.$i18n.locale

        if (['SUVA', 'SVTI'].indexOf(registrationOffice) < 0) {
          registrationOffice = 'SVTI'
        }

        if (['de', 'fr', 'it'].indexOf(languageCode) < 0) {
          languageCode = 'de'
        }

        return PRIVCY_POLICY_LINKS[registrationOffice][languageCode]
      }
    },
    data() {
      return {
        dialog: true
      }
    },
    methods: {
      accept() {
        localStorage.setItem(COOKIES_ACCEPTED_KEY, 'true')
        this.dialog = false
      },
      noticed() {
        this.dialog = false
      }
    },
    beforeMount() {
      let v = localStorage.getItem(COOKIES_ACCEPTED_KEY)
      this.dialog = (v !== 'true')
    }
  }
</script>
<style lang="sass" scoped>
  .cookie-dialog
    position: absolute !important
    margin: 4px
    max-width: 80%
    bottom: 0 !important
    right: 0 !important
</style>
